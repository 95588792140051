import React from "react"

import { useAuth } from "../../services"
import { UsersGroupsAssigner } from "../Assigners"
import { toId } from "../../utils"
import { jobFiltersStateVar, useJobFiltersStore } from "../../data"

const AssignedToPickers = ({ classes }) => {
  const { location } = useAuth()
  const { jobsFiltersStore } = useJobFiltersStore()

  const { toVariant, toOpenPickerInit, toUsers, toGroups, byVariant } = jobsFiltersStore
  const toInitialPicked = toUsers && toGroups ? [...toGroups, ...toUsers] : null

  const handleSet = (prop, value) => {
    jobFiltersStateVar({
      ...jobFiltersStateVar(),
      [prop]: value,
    })
  }

  const handleToPickedChanged = (picked) => {
    jobFiltersStateVar({
      ...jobsFiltersStore,
      toHasEveryone: picked ? picked.some((p) => p.__typename === "Everyone") : false,
      toUsers: picked ? picked.filter((p) => p.__typename === "User") : [],
      toGroups: picked ? picked.filter((p) => p.__typename === "Group") : [],
    })
  }

  const handleSetVariant = (target, variant) => {
    handleSet(`${target}Variant`, variant)
    setTimeout(() => {
      handleSet(`${target}OpenPickerInit`, variant === "select")
    }, 1000)
  }

  if (!toInitialPicked) {
    return null
  }

  return (
    <UsersGroupsAssigner
      variant={toVariant}
      title="Assigned to"
      locations={[toId(location)]}
      onPickedChanged={handleToPickedChanged}
      onVariantChange={(variant) => handleSetVariant("to", variant)}
      openPickerAfterInit={toOpenPickerInit}
      initialPicked={toInitialPicked}
      initialSelectValue={toVariant === "select" && toInitialPicked.length === 1 ? toId(toInitialPicked[0]) : null}
      selectAllowAdvanced
      className={toVariant === "advanced" && byVariant === "advanced" && classes.assignedTo}
      allowSelf
      assigneeFlow="none"
    />
  )
}

export { AssignedToPickers }

import React from "react"
import { Route, Switch } from "react-router-dom"
import { AreaHeader, Content, DesktopSensorsReportsButton, RowBox } from "../../components"
import Sensors from "./Sensors"
import Sensor from "./Sensor"
import { useQuerySettings } from "../../data"
import { useAuth } from "../../services"

const Index = () => {
  const { lang } = useQuerySettings()
  const { location } = useAuth()

  return (
    <>
      <AreaHeader
        title={lang.area.sensors}
        titleIcon="sensors-light"
        subTitle={location ? location.name : null}
        desktopButtons={
          <RowBox>
            <DesktopSensorsReportsButton />
          </RowBox>
        }
      />

      <Content full start mb={5}>
        <Switch>
          <Route path="/sensors/:id/:days" component={Sensor} />
          <Route path="/sensors" component={Sensors} />
        </Switch>
      </Content>
    </>
  )
}

export default Index

import React, { useState } from "react"
import { Box, Divider, makeStyles, Modal } from "@material-ui/core"
import { ActionCard } from "."
import { ColumnBox, LoadingSpinner, NoItemsMessage, RowBox } from ".."
import { FullscreenIconButton } from "../Buttons/FullscreenIconButton"

const useFullscreenStyles = makeStyles(() => ({
  root: {
    height: ({ height }) => height || "calc(100vh - 150px)",
    overflowY: "auto",
  },
}))

const FullscreenReportContainer = ({ height, children }) => {
  const classes = useFullscreenStyles({ height })

  return <Box className={classes.root}>{children}</Box>
}

const useCardStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fullscreen: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
  },
}))

const ReportSectionActionCard = ({
  children,
  loading,
  fullHeight = true,
  title,
  noData,
  allowFullscreen = true,
  onFullscreen,
  tools,
  layout = "column",
  defaultFullscreen = false,
  ...props
}) => {
  const classes = useCardStyles()
  const [fullscreen, setFullscreen] = useState(defaultFullscreen)

  const handleFullscreen = () => {
    const newFullscreen = !fullscreen
    onFullscreen && onFullscreen(newFullscreen)
    setFullscreen(newFullscreen)
  }

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      handleFullscreen()
    }
  }

  const handleExitFullscreen = () => {
    if (fullscreen) {
      handleFullscreen()
    }
  }

  const rightTools = (
    <RowBox alignItems="center">
      {tools}
      {allowFullscreen && (
        <Box ml={1}>
          <FullscreenIconButton fullscreen={fullscreen} onClick={handleFullscreen} />
        </Box>
      )}
    </RowBox>
  )

  const column = layout === "column"

  const display = (
    <ActionCard
      cursor={props.cardProps?.onClick ? "pointer" : "default"}
      fullHeight={fullHeight}
      title={title}
      rightChildren={
        <>
          {!noData && !column && (
            <RowBox alignItems="center">
              {loading && (
                <ColumnBox alignItems="center" justifyContent="center" style={{ height: "100%" }}>
                  <LoadingSpinner size={45} />
                </ColumnBox>
              )}
              {!loading && (
                <Box style={{ height: "100%" }}>
                  {typeof children === "function" ? children({ fullscreen }) : children}
                </Box>
              )}
            </RowBox>
          )}
          {!noData && column && rightTools}
          {noData && <NoItemsMessage p={0}>No data</NoItemsMessage>}
        </>
      }
      {...props}
    >
      {!noData && (
        <>
          {column && (
            <>
              {title && (
                <Box style={{ marginLeft: -18, marginRight: -18 }} my={2}>
                  <Divider />
                </Box>
              )}
              {loading && (
                <ColumnBox alignItems="center" justifyContent="center" style={{ height: "100%" }}>
                  <LoadingSpinner size={60} />
                </ColumnBox>
              )}
              {!loading && (
                <Box>
                  {typeof children === "function"
                    ? children({ fullscreen, raiseExitFullscreen: fullscreen ? handleExitFullscreen : null })
                    : children}
                </Box>
              )}
            </>
          )}
        </>
      )}
    </ActionCard>
  )

  if (fullscreen) {
    return (
      <Modal open className={classes.backdrop} onKeyDown={handleEscape}>
        <Box className={classes.fullscreen}>{display}</Box>
      </Modal>
    )
  }

  return display
}

export { ReportSectionActionCard, FullscreenReportContainer }

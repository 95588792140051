import React, { useEffect, useMemo, useState } from "react"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import { Box, makeStyles } from "@material-ui/core"
import {
  RowBox,
  ReportSectionActionCard,
  HorizontalLegend,
  HorizontalLegendItem,
  NoItemsMessage,
  FullscreenReportContainer,
} from ".."
import { mapToIds, toId } from "../../utils"
import { useAuth } from "../../services"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"
import { useLazyQueryReportJobsLocationsCompletions } from "../../data/report/useQueryReportJobsLocationsOverall"
import { CircularJobCompletionChart } from "../Charts/CircularJobCompletionChart"
import { useQueryRegions } from "../../data/locations/useQueryRegions"

const useStyles = makeStyles((theme) => ({
  results: {
    overflowX: "auto",
  },
  resultsFullscreen: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
  legendFullscreen: {
    position: "absolute",
    bottom: theme.spacing(4),
    left: theme.spacing(4),
  },
}))

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank > b.rank ? -1 : 1)

const LocationsJobCompletionReport = ({ gt, lt, locations, groups, onLocationClick }) => {
  const [sort, setSort] = useState("alpha")
  const [fullscreen, setFullscreen] = useState(false)
  const classes = useStyles()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [loadReportLocationsOverview, { data, loading }] = useLazyQueryReportJobsLocationsCompletions()
  const { data: regionsData, loading: regionsLoading } = useQueryRegions()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    loadReportLocationsOverview({
      variables: {
        input: {
          locations: filterLocations,
          groups: filterGroups,
          gt,
          lt,
        },
      },
    })
  }, [groups, gt, loadReportLocationsOverview, locations, lt, userLocations])

  const handleLocationClick = ({ id }) => {
    onLocationClick && onLocationClick(id)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data?.report.jobs.locations.completions
      .map(({ id, name, region, completions }) => ({
        id,
        name,
        region,
        completions,
        rank:
          completions.total > 0 ? (completions.completedOnTime + completions.completedLate) / completions.total : -1,
      }))
      .sort((a, b) => sortData(sort, a, b))
  }, [data, sort])

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const getRegionName = (id) => {
      const region = regionsData?.regions.find((item) => toId(item) === id)
      return region?.name || ""
    }

    const header = ["Name", "Region", "On-time", "Late", "In-Progress", "Overdue", "Uncompleted"]
    const rows = [...data.report.jobs.locations.completions].map(
      ({
        name,
        region,
        completions: { completedOnTime, completedLate, inProgress, uncompleted, uncompletedOverdue },
      }) =>
        [
          stringFormatter(name),
          stringFormatter(getRegionName(region)),
          completedOnTime,
          completedLate,
          inProgress,
          uncompleted,
          uncompletedOverdue,
        ].join(",")
    )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "locations-completion.csv", "text/csv")
  }

  const display = (
    <>
      <RowBox alignItems="flex-start" className={fullscreen ? classes.resultsFullscreen : classes.results}>
        {chartData.map(({ id, name, completions }) => (
          <CircularJobCompletionChart
            key={id}
            text={name}
            data={completions}
            onClick={() => handleLocationClick({ id })}
          />
        ))}
      </RowBox>
      <HorizontalLegend className={fullscreen ? classes.legendFullscreen : undefined}>
        <HorizontalLegendItem text="Completed (On time)" color="success" />
        <HorizontalLegendItem text="Completed (Late)" color="warning" />
        <HorizontalLegendItem text="In Progress" color="primary" />
        <HorizontalLegendItem text="Overdue" color="secondary" />
        <HorizontalLegendItem text="Uncompleted" color="background" />
      </HorizontalLegend>
    </>
  )

  return (
    <ReportSectionActionCard
      title="Jobs completed by location"
      detail="Which locations are completing their assigned jobs?"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        chartData?.length > 1 ? (
          <RowBox>
            <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading || regionsLoading} />
            <Box ml={1}>
              <ReportToolsIconButton
                title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
                icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                onClick={handleSort}
                disabled={loading}
              />
            </Box>
          </RowBox>
        ) : null
      }
    >
      {chartData?.length > 0 && (
        <>
          {fullscreen ? (
            <FullscreenReportContainer height="calc(100vh - 182px)">{display}</FullscreenReportContainer>
          ) : (
            display
          )}
        </>
      )}
      {chartData.length === 0 && <NoItemsMessage>No data found</NoItemsMessage>}
    </ReportSectionActionCard>
  )
}

export { LocationsJobCompletionReport }

import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { Chart, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { EventTracker, HoverState, Palette } from "@devexpress/dx-react-chart"
import { RowBox, ColumnBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    textAlign: "center",
    minWidth: 100,
    cursor: ({ clickable }) => (clickable ? "pointer" : "default"),
  },
  text: {
    maxWidth: 100,
    fontSize: 12,
  },
  value: {
    color: theme.palette.text.secondary,
    position: "absolute",
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: ({ total }) => (!total ? 600 : 800),
    marginTop: 40,
    zIndex: 1,
  },
}))

const CircularStatusChart = ({ text, value, ...props }) => {
  const { total, unresolved, inProgress, blocked, resolved, cancelled } = value
  const classes = useStyles({ clickable: !!props.onClick, complete: value === 100 })
  const theme = useTheme()

  const colours = [
    theme.palette.status.unresolved,
    theme.palette.status.in_progress,
    theme.palette.status.blocked,
    theme.palette.status.resolved,
    theme.palette.status.cancelled,
  ]

  const chartData = [
    { argument: "Unresolved", value: unresolved },
    { argument: "In progress", value: inProgress },
    { argument: "Blocked", value: blocked },
    { argument: "Resolved", value: resolved },
    { argument: "Cancelled", value: cancelled },
  ]

  const TooltipContent = ({ targetItem: { point } }) => {
    const { argument, value: argumentValue } = chartData[point]
    const percentage = Math.floor((argumentValue / total) * 100)

    return (
      <Box>
        <Box>
          <strong>{argument}</strong>
        </Box>
        <Box>
          {percentage}% ({argumentValue} actions)
        </Box>
      </Box>
    )
  }

  return (
    <>
      <ColumnBox alignItems="center" justifyContent="flex-start" mr={2} className={classes.root} mb={1} {...props}>
        <ColumnBox>
          <Chart width="100" height="100" data={chartData}>
            <Palette scheme={colours} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.8} />
            <EventTracker />
            {total > 0 && <Tooltip contentComponent={TooltipContent} />}
            <HoverState />
          </Chart>
        </ColumnBox>
        <Box className={classes.value}>{total}</Box>
        <RowBox alignItems="center" justifyContent="center">
          <Box className={classes.text}>{text}</Box>
        </RowBox>
      </ColumnBox>
    </>
  )
}
export { CircularStatusChart }

import React from "react"
import { List } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { ErrorBoundary, LoadingSpinner } from ".."
import { useMutationReadNotification } from "../../data"
import { toId, useMountAwareReactiveVar } from "../../utils"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"
import { NotificationUpdateVersion } from "./NotificationUpdateVersion"
import { NotificationListItem } from "./NotificationListItem"

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
}))

const NotificationList = ({ loading, error, data, onNavigate, limit = 10, spinnerSize = 30 }) => {
  const classes = useStyles()
  const [readNotifications] = useMutationReadNotification()
  const hasNewDeviceClientVersion = useMountAwareReactiveVar(newDeviceClientAvailableVar)

  if (loading)
    return (
      <div style={{ margin: "0 50%" }}>
        <LoadingSpinner size={spinnerSize} />
      </div>
    )
  if (!data || (data && !data.notifications)) return null

  if (data && data.notifications && data.notifications.items && !data.notifications.items.length)
    return <p className={classes.empty}>You don't currently have any notifications</p>

  const handleClick = (notification) => {
    if (!notification.read) {
      readNotifications({
        variables: { ids: [notification.id] },
      })
    }
    if (onNavigate) onNavigate()
  }

  return (
    <ErrorBoundary>
      <List disablePadding>
        {hasNewDeviceClientVersion && <NotificationUpdateVersion classes={classes} />}
        {(limit ? [...data.notifications.items].slice(0, limit) : [...data.notifications.items])
          .sort((a, b) => (moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1))
          .map((notification) => (
            <NotificationListItem key={toId(notification)} notification={notification} onClick={handleClick} />
          ))}
      </List>
    </ErrorBoundary>
  )
}

export default NotificationList

import { useQuery, useLazyQuery, gql } from "@apollo/client"

const SCHEDULE_TRIGGER_FIELDS = gql`
  fragment ScheduleTriggerFields on ScheduleTrigger {
    id
    name
    scheduleSource {
      schedules
      timeZone
      locations {
        id
        name
      }
    }
    targetType
    notifyUsersTarget {
      methods
      users {
        id
        fullName
      }
      groups {
        id
        name
      }
      output
    }
    notifyEmailsTarget {
      output
      emails
    }
    integrationTarget {
      id
    }
    pausedAt
    pausedBy {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
    }
    author {
      id
      fullName
    }
    createdAt
    updatedAt
  }
`

const EVENT_TRIGGER_FIELDS = gql`
  fragment EventTriggerFields on EventTrigger {
    id
    name
    eventSource {
      events
      locations {
        id
        name
      }
      process {
        id
        name
      }
      trainingCourses {
        id
        name
      }
      trainingModules {
        id
        name
      }
      processStep
      actionPriorities
    }
    targetType
    notifyUsersTarget {
      methods
      users {
        id
        fullName
      }
      groups {
        id
        name
      }
      output
    }
    notifyEmailsTarget {
      output
      emails
    }
    notifyAuthorTarget {
      methods
      output
    }
    integrationTarget {
      id
    }
    pausedAt
    pausedBy {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
    }
    author {
      id
      fullName
    }
    createdAt
    updatedAt
  }
`

const TRIGGERS_QUERY = gql`
  query Triggers($filter: TriggerFilterInput) {
    triggers(filter: $filter) {
      ... on ScheduleTrigger {
        ...ScheduleTriggerFields
      }
      ... on EventTrigger {
        ...EventTriggerFields
      }
    }
  }
  ${SCHEDULE_TRIGGER_FIELDS}
  ${EVENT_TRIGGER_FIELDS}
`

const useQueryTriggers = (options) => useQuery(TRIGGERS_QUERY, options)

const useLazyQueryTriggers = (options) => useLazyQuery(TRIGGERS_QUERY, options)

export { useQueryTriggers, useLazyQueryTriggers, SCHEDULE_TRIGGER_FIELDS, EVENT_TRIGGER_FIELDS }

import React, { useEffect, useRef, useState } from "react"
import pluralize from "pluralize"
import { TemplateOutlinedSelect } from ".."
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { useQueryProcess } from "../../data"
import { toId } from "../../utils"
import { ValueText } from "./ValueText"

const ProcessName = ({ process, prefix = "Job: ", fallback = "Unknown job" }) => {
  const { data, loading } = useQueryProcess({
    variables: { id: toId(process) },
    skip: !process,
    fetchPolicy: "cache-first",
  })

  if (loading) {
    return "Loading..."
  }

  const processName = data?.process?.name

  return processName ? `${prefix}${processName}` : fallback
}

const ProcessValueText = ({ value }) => {
  if (!value) {
    return "Job"
  }

  const multiple = Array.isArray(value)

  if (value === "all" || (multiple && value.includes("all"))) {
    return "All jobs"
  }

  const count = multiple ? value.length : 1

  if (multiple && count === 0) {
    return "No jobs"
  }

  if (count === 1) {
    return <ProcessName process={value} />
  }

  return pluralize("job", count, true)
}

const ProcessChipMenuItem = ({ value, onChange, processFilters, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(event.target.value)
  }

  return (
    <ChipMenuItem>
      <TemplateOutlinedSelect value={value} onChange={handleChange} filters={processFilters} {...props} hasAll />
    </ChipMenuItem>
  )
}

const ProcessChipMenu = ({ value, onChange, onRegionChange, processFilters = {}, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onChange && onChange(props?.multiple ? ["all"] : "all")
  }

  return (
    <ChipMenu
      menuRef={menuRef}
      text={
        <ValueText>
          <ProcessValueText value={value} />
        </ValueText>
      }
    >
      <ProcessChipMenuItem value={nextValue} onChange={handleChange} processFilters={processFilters} {...props} />
      <ChipMenuActions onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { ProcessChipMenu, ProcessChipMenuItem, ProcessValueText }

import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  makeStyles,
  DialogActions,
  useMediaQuery,
  Button,
} from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { DialogTitleCloser, InlineTitle, InlineTitleCloser, LoadingSpinner } from ".."
import { LinkButton } from "../LinkButton"

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: ({ maxWidth }) => (maxWidth ? "default" : theme.dimensions.dialogs.pickers.width),
    marginLeft: ({ xs }) => (xs ? 0 : null),
    marginRight: ({ xs }) => (xs ? 0 : null),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
  },
  actions: {
    padding: ({ selectVariant, cancelVariant }) =>
      selectVariant === "button" || cancelVariant === "button"
        ? `0 ${theme.spacing(3)}px 16px ${theme.spacing(3)}px`
        : `0 ${theme.spacing(3)}px 29px ${theme.spacing(3)}px`,
  },
  buttons: {
    color: theme.palette.primary.main,
  },
  inlineWrapper: {
    overflowX: "hidden",
  },
}))

const SelectorMaster = ({
  open,
  title,
  cancelText = "Cancel",
  selectText = "Select",
  header,
  form,
  maxWidth,
  isInline,
  onSelect,
  onCancel,
  onClose,
  selectLoading = false,
  selectDisabled = false,
  cancelDisabled = false,
  cancelVariant = "link",
  selectVariant = "link",
  inlineTitleProps = {},
  inlineContentProps = {},
  dialogContentProps = {},
  dialogContentInnerProps = {},
  dialogTitleClassName = "",
  dialogTitleProps = {},
  dialogContentClassName = "",
  dialogActionsClassName = "",
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs, maxWidth, selectVariant, cancelVariant })

  const handleSelect = () => {
    onSelect && onSelect()
  }

  const handleCancel = () => {
    onCancel && onCancel()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  if (isInline) {
    return (
      <Box className={classes.inlineWrapper}>
        {open && (
          <>
            <InlineTitle {...inlineTitleProps}>
              {title}
              <InlineTitleCloser onClose={onClose} />
            </InlineTitle>
            <Box p={2} {...inlineContentProps}>
              {form}
            </Box>
          </>
        )}
      </Box>
    )
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title" className={`${classes.title} ${dialogTitleClassName}`} {...dialogTitleProps}>
        {title}
        {onClose && <DialogTitleCloser onClose={handleClose} />}
        {!!header && <div>{header}</div>}
      </DialogTitle>
      <DialogContent className={dialogContentClassName} {...dialogContentProps}>
        <Box ml={-1} {...dialogContentInnerProps}>
          {form}
        </Box>
      </DialogContent>
      {(onCancel || onSelect) && (
        <DialogActions className={`${classes.actions} ${dialogActionsClassName}`}>
          {onCancel && (
            <Box mr="auto">
              <Box>
                {cancelVariant === "link" && (
                  <LinkButton className={classes.buttons} onClick={handleCancel} disabled={cancelDisabled}>
                    {cancelText}
                  </LinkButton>
                )}
                {cancelVariant === "button" && (
                  <Button variant="contained" onClick={handleCancel} disabled={cancelDisabled} fullWidth>
                    {cancelText}
                  </Button>
                )}
              </Box>
            </Box>
          )}
          {onSelect && (
            <Box ml="auto" display="flex" flexDirection="row">
              <Box ml={2}>
                {selectVariant === "link" && (
                  <LinkButton className={classes.buttons} onClick={handleSelect} disabled={selectDisabled}>
                    {selectText}
                  </LinkButton>
                )}
                {selectVariant === "button" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSelect}
                    disabled={selectDisabled || selectLoading}
                  >
                    {!selectLoading && selectText}
                    {selectLoading && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export { SelectorMaster }

import React, { useEffect, useState } from "react"
import { Box, Grid } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { FieldSectionHeading, ModuleHeading, PaperBox, RequirePermissions } from "../../../components"
import { useQueryGroups } from "../../../data"
import { mapToIds, multipleSelectChange, toId, useDateUtils, useReportUtils } from "../../../utils"
import { DateRangeChipMenu } from "../../../components/ChipMenu/DateRangeChipMenu"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { GroupChipMenu } from "../../../components/ChipMenu/GroupChipMenu"
import { TagChipMenu } from "../../../components/ChipMenu/TagChipMenu"
import { ActionsStatistic } from "../../../components/Reports/ActionsStatistic"
import { JobsStatistic } from "../../../components/Reports/JobsStatistic"
import { TrainedStatistic } from "../../../components/Reports/TrainedStatistic"
import { LocationsRankingsReport } from "../../../components/Reports/LocationsRankingsReport"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"
import { LocationsJobCompletionReport } from "../../../components/Reports/LocationsJobCompletionReport"
import GroupsJobCompletionReport from "../../../components/Reports/GroupsJobCompletionReport"
import { RegionsJobCompletionReport } from "../../../components/Reports/RegionsJobCompletionReport"
import { useAuth } from "../../../services"

const Overview = () => {
  const { days, locations, groups, tags } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const {
    settings: { regions },
  } = useAuth()
  const {
    getNavigateToOverviewLink,
    getNavigateToJobsLink,
    // getNavigateToAuditsLink,
    getNavigateToActionsLink,
    getNavigateToTrainingLink,
  } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const { data: groupsData } = useQueryGroups()

  const groupIds = groups?.split("-") || ["all"]
  const locationIds = locations?.split("-") || ["all"]
  const tagIds = tags?.split("-") || ["all"]

  const filteredGroupIds = groupIds.filter((id) => id !== "all")
  const filteredTagIds = tagIds.filter((id) => id !== "all")

  useEffect(() => {
    let newGt = null
    let newLt = null
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      newGt = momentToScheduleDate(moment(daysParts[0]))
      newLt = momentToScheduleDate(moment(daysParts[1]))
    } else {
      newGt = momentToScheduleDate(moment().subtract(days, "days"))

      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }
    }
    setGt(newGt)
    setLt(newLt)
  }, [days, momentToScheduleDate])

  const handleFiltersMenuApply = (values) => {
    const { days: newDays, locations: newLocations, groups: newGroups, tags: newTags } = values
    history.push(getNavigateToOverviewLink(newDays, newLocations, newGroups, newTags))
  }

  const handleDaysChange = (value) => {
    history.push(getNavigateToOverviewLink(value, locationIds, groupIds, tagIds))
  }

  const handleLocationsChange = (value) => {
    history.push(getNavigateToOverviewLink(days, multipleSelectChange(locationIds, value), groupIds, tagIds))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToOverviewLink(days, mapToIds(regionLocations), groupIds, tagIds))
  }

  const handleGroupsChange = (value) => {
    history.push(getNavigateToOverviewLink(days, locationIds, multipleSelectChange(groupIds, value), tagIds))
  }

  const handleTagsChange = (value) => {
    history.push(getNavigateToOverviewLink(days, locationIds, groupIds, multipleSelectChange(tagIds, value)))
  }

  const handleRegionClick = (id) => {
    const newRegion = regions.find((region) => toId(region) === toId(id))
    if (newRegion) {
      handleLocationsChange(newRegion.locations.map((location) => toId(location)))
    }
  }

  const handleLocationClick = (id) => {
    history.push(getNavigateToOverviewLink(days, [id], groupIds, tagIds))
  }

  const handleGroupClick = (id) => {
    history.push(getNavigateToOverviewLink(days, locationIds, [id], tagIds))
  }

  // const handleJobClick = (process) => {
  //   history.push(getNavigateToJobsLink(days, locationIds, groupIds, process, tagIds))
  // }

  const handleClear = () => {
    history.push(getNavigateToOverviewLink(7, ["all"], ["all"], null))
  }

  const hasGroups = groupsData?.groups.length > 0

  const showRegions =
    regions.some((region) => region.id !== "none") &&
    (locationIds.includes("all") ||
      regions.filter((region) => region.locations.some((regionLocation) => locationIds.includes(toId(regionLocation))))
        .length > 1)

  const showLocations = !showRegions || (locationIds.length > 0 && !locationIds.includes("all"))

  const showSingleLocation = locationIds.length === 1 && locationIds[0] !== "all"

  return (
    <>
      <ReportsFilters
        values={{
          days,
          locations: locationIds,
          groups: groupIds,
          tags: tagIds,
        }}
        menu={{
          days: true,
          locations: true,
          groups: hasGroups,
          tags: true,
        }}
        onApply={handleFiltersMenuApply}
        onClear={handleClear}
      >
        <Box ml={0.5}>
          <DateRangeChipMenu value={days} onChange={handleDaysChange} />
        </Box>
        <Box ml={0.5}>
          <LocationChipMenu
            value={locationIds}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box ml={0.5}>
            <GroupChipMenu value={groupIds} onChange={handleGroupsChange} multiple />
          </Box>
        )}
        <Box ml={0.5}>
          <TagChipMenu value={tagIds} onChange={handleTagsChange} multiple />
        </Box>
      </ReportsFilters>

      <Box mt={3} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs>
            <JobsStatistic
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={filteredGroupIds}
              tags={filteredTagIds}
              link={{ to: getNavigateToJobsLink(days, locationIds, groupIds, "all"), text: "Jobs" }}
            />
          </Grid>
          <Grid item xs>
            <ActionsStatistic
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={filteredGroupIds}
              tags={filteredTagIds}
              link={{ to: getNavigateToActionsLink(days, locationIds, groupIds, "all"), text: "Actions" }}
            />
          </Grid>
          {/* <Grid item xs={6} sm={3}>
          <PaperBox>
            <StatisticDisplay
              icon="audits-dark"
              label="Average score"
              breakdown="Across 86 scored jobs"
              value={5}
              total={10}
              link={{ to: getNavigateToAuditsLink(days, locationIds, groupIds), text: "Scoring" }}
            />
          </PaperBox>
        </Grid> */}
          <RequirePermissions features="training">
            <Grid item xs>
              <PaperBox>
                <TrainedStatistic
                  gt={gt}
                  lt={lt}
                  locations={locationIds}
                  groups={filteredGroupIds}
                  tags={filteredTagIds}
                  link={{ to: getNavigateToTrainingLink(days, locationIds, groupIds), text: "Training" }}
                />
              </PaperBox>
            </Grid>
          </RequirePermissions>
        </Grid>
      </Box>

      {!showSingleLocation && (
        <>
          {showRegions && (
            <Box mb={4}>
              <RegionsJobCompletionReport
                gt={gt}
                lt={lt}
                locations={locationIds}
                groups={groupIds}
                tags={tagIds}
                onRegionClick={handleRegionClick}
              />
            </Box>
          )}

          {showLocations && (
            <Box mb={4}>
              <LocationsJobCompletionReport
                gt={gt}
                lt={lt}
                locations={locationIds}
                groups={groupIds}
                tags={tagIds}
                onLocationClick={handleLocationClick}
              />
            </Box>
          )}

          <ModuleHeading>Rankings</ModuleHeading>

          <FieldSectionHeading>Highest performing</FieldSectionHeading>

          <Box mb={3}>
            <LocationsRankingsReport
              variant="highest"
              count={6}
              numberOfItemsPerRow={3}
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={filteredGroupIds}
              tags={filteredTagIds}
              onLocationClick={handleLocationClick}
            />
          </Box>

          <FieldSectionHeading>Lowest performing</FieldSectionHeading>

          <LocationsRankingsReport
            variant="lowest"
            count={6}
            numberOfItemsPerRow={3}
            gt={gt}
            lt={lt}
            locations={locationIds}
            groups={filteredGroupIds}
            tags={filteredTagIds}
            onLocationClick={handleLocationClick}
          />
        </>
      )}

      {showSingleLocation && (
        <>
          <Box mb={2}>
            <GroupsJobCompletionReport
              gt={gt}
              lt={lt}
              locations={locationIds}
              groups={groupIds}
              onGroupClick={handleGroupClick}
            />
          </Box>
          {/* <Box mb={4}>
            <ActionsGroupReport gt={gt} lt={lt} locations={locationIds} groups={groupIds} />
          </Box> */}
        </>
      )}
    </>
  )
}

export default Overview

import Axios from "axios"
import Config from "react-global-configuration"
import FileDownload from "js-file-download"
import { authStateVar } from "../authentication"

const downloadActionExport = async (filters, options) => {
  const { httpBaseUri } = Config.get()
  const { onProgress } = options || {}
  const {
    principal: { token },
  } = authStateVar()

  const response = await Axios({
    url: `${httpBaseUri}/action-export`,
    method: "POST",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: filters,
    onDownloadProgress: (progressEvent) => {
      if (onProgress) {
        onProgress({ loaded: progressEvent.loaded, total: progressEvent.total })
      }
    },
  })

  if (response.status !== 200) {
    throw new Error(response.data?.message || "Failed to download action export")
  }

  FileDownload(response.data, "actions-detail.csv")
}

export default downloadActionExport

import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: ({ maxWidth }) => maxWidth,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

const ValueText = ({ children, maxWidth = 150 }) => {
  const classes = useStyles({ maxWidth })

  return <Box className={classes.root}>{children}</Box>
}

export { ValueText }

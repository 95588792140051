import React, { useEffect, useRef } from "react"
import { ReportSectionActionCard, FullscreenReportContainer, RowBox, FormatScheduleDate } from ".."
import { mapToIds, toId } from "../../utils"
import { useAuth } from "../../services"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import { useLazyQueryReportActionsLocationsDetail } from "../../data/report/useQueryReportActionsLocationsDetail"
import ActionsTableReportNext from "./ActionsTableReportNext"

const ActionsTableReport = ({
  gt,
  lt,
  process,
  step,
  locations,
  groups,
  tags,
  statuses,
  priorities,
  defaultFullscreen = false,
  onFullscreen,
}) => {
  const reportRef = useRef()
  const [load, { data, loading }] = useLazyQueryReportActionsLocationsDetail()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    const filterLocations = locations.includes("all") ? userLocations : locations
    const filterStatuses = statuses.includes("all") ? null : statuses
    const filterPriorities = priorities.includes("all") ? null : priorities
    const filterGroups = groups.includes("all") ? null : groups
    const filterTags = tags.includes("all") ? null : tags

    load({
      variables: {
        input: {
          process: toId(process),
          processStep: toId(step),
          locations: mapToIds(filterLocations),
          groups: filterGroups,
          tags: filterTags,
          statuses: filterStatuses,
          priorities: filterPriorities,
          gt,
          lt,
        },
      },
    })
  }, [groups, gt, load, locations, lt, priorities, process, statuses, step, tags, userLocations])

  const handleDownloadData = () => {
    reportRef?.current?.downloadData()
  }

  const handleFullscreen = (fullscreen) => {
    onFullscreen && onFullscreen(fullscreen)
  }

  const userLocation = userLocations.find((location) => location.id === locations[0])

  return (
    <ReportSectionActionCard
      title="Actions"
      detail={
        loading ? (
          "Loading..."
        ) : (
          <>
            {userLocation?.name || "Unknown"} from <FormatScheduleDate value={gt} /> to{" "}
            {lt ? <FormatScheduleDate value={lt} /> : <>date</>}
          </>
        )
      }
      defaultFullscreen={defaultFullscreen}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
        </RowBox>
      }
      loading={loading || !data}
    >
      {({ fullscreen }) => {
        const display = (
          <ActionsTableReportNext
            reportRef={reportRef}
            gt={gt}
            lt={lt}
            locations={locations}
            groups={groups}
            tags={tags}
            statuses={statuses}
            priorities={priorities}
            showLocation={false}
            showTopIndicator={false}
            showDownload={false}
          />
        )

        if (fullscreen) {
          return <FullscreenReportContainer>{display}</FullscreenReportContainer>
        }

        return display
      }}
    </ReportSectionActionCard>
  )
}

export default ActionsTableReport

import { ACTION_PRIORITY, EVENT_NAME, NOTIFICATION_TYPE } from ".."

const useQuerySettings = () => ({
  // language
  lang: {
    area: {
      dashboard: "Home",
      jobs: "Active Jobs",
      knowledge: "Knowledge Base",
      hub: "Comms Hub",
      labels: "Labels",
      manager: "Managers Hub",
      more: "More",
      people: "People",
      training: "Training",
      audits: "Audits",
      assets: "Assets",
      suppliers: "Suppliers",
      sensors: "Sensors",
      reports: "Reports",
      templates: "Job Manager",
    },
    areaShort: {
      dashboard: "Home",
      jobs: "Jobs",
      knowledge: "Knowledge",
      hub: "Comms",
      labels: "Labels",
      manager: "Manager",
      more: "More",
      people: "People",
      training: "Training",
      audits: "Audits",
      assets: "Assets",
      suppliers: "Suppliers",
      sensors: "Sensors",
      reports: "Reports",
      templates: "Job Manager",
    },
    action: {
      login: "Sign In",
      logout: "Sign Out",
    },
    input: {
      email_address: "Email Address",
      password: "Password",
    },
    notification: {
      [`message${NOTIFICATION_TYPE.GENERAL}`]: (notification) => notification.message,
      [`message${NOTIFICATION_TYPE.PROCESS}`]: () => "has assigned you a new process",
      [`message${NOTIFICATION_TYPE.TASK}`]: () => "has assigned you a new task",
      [`message${NOTIFICATION_TYPE.KNOWLEDGE}`]: () =>
        "requires your confirmation on an updated knowledge base article",
      [`message${NOTIFICATION_TYPE.POST}`]: (notification, includeTitle = true) => {
        if (notification.hasConfirm) {
          if (includeTitle) {
            return `requires your confirmation on ${
              notification.post ? notification.post.title || "a post" : "<post removed>"
            }`
          }
          return "requires your confirmation"
        }
        return `shared ${notification.post ? notification.post.title : "<post removed>"}`
      },
      [`message${NOTIFICATION_TYPE.JOB}`]: (notification) => notification.message,
      [`message${NOTIFICATION_TYPE.ACTION}`]: (notification, includeTitle = true) => {
        if (notification.eventName === EVENT_NAME.ACTION_OVERDUE) {
          return `Overdue action: ${notification.action?.title || "<action removed>"}`
        }

        const adjective = notification.eventName === EVENT_NAME.ACTION_UPDATED ? "updated" : "raised"

        return notification?.message && notification?.message !== "Empty"
          ? notification.message
          : `${adjective} ${
              notification.action?.priority === ACTION_PRIORITY.HIGH
                ? "a high priority"
                : notification.action?.priority === ACTION_PRIORITY.CRITICAL
                ? "a critical"
                : "an"
            } action for ${includeTitle ? notification.action?.title || "<action removed>" : "your attention"}`
      },
      [`message${NOTIFICATION_TYPE.TRAINING}`]: (notification, includeTitle = true) =>
        `assigned you training course ${
          includeTitle ? notification.userTraining?.name || "<training removed>" : "for completion"
        }`,
      [`message${NOTIFICATION_TYPE.ACCREDITATION}`]: ({ userAccreditations }) =>
        `requested ${
          userAccreditations.length > 1
            ? `${userAccreditations.length} accreditations from you`
            : `your ${userAccreditations[0]?.accreditation?.name || "<accreditation removed>"} accreditation`
        }`,
    },
  },
  // org
  org: {
    jobs: {
      startHour: 7,
    },
  },
})

export { useQuerySettings }
